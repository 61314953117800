@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
:root {
  --theme-clr: #ff9800;
  --theme-soft-clr: rgba(255, 152, 0, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.9);
  display: grid;
  place-items: center;
  z-index: 100;
  transition: 0.3s all ease;
}

.loader img {
  height: 80px;
  animation: rotate 2s infinite;
}

.loader.false {
  opacity: 0;
  pointer-events: none;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=index.css.map */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  padding: 10px 30px;
  width: calc(100vw);
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 10vh;
  z-index: 5;
}

.navbar .navbarLeft {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.navbar .navbarLeft h3 {
  color: #fff;
  white-space: nowrap;
  margin-left: 15px;
  font-weight: 500;
  font-size: 1.5em;
  letter-spacing: 5px;
}

.navbar .navbarRight {
  display: flex;
  align-items: center;
}

.navbar .navbarRight .navLink {
  color: #fff;
  font-size: 0.95rem;
}

.navbar .navbarRight .navLink a {
  color: #fff;
  text-decoration: none;
}

.navbar .navbarRight .navLink.hasDropdown {
  position: relative;
}

.navbar .navbarRight .navLink.hasDropdown > a {
  cursor: pointer;
}

.navbar .navbarRight .navLink.hasDropdown > a::after {
  content: "\f0d7";
  font-family: "FontAwesome";
  color: #fff;
  margin-left: 0.3em;
}

.navbar .navbarRight .navLink.hasDropdown .dropdownMenu {
  position: absolute;
  top: 1.7em;
  left: 0;
  background-color: #fff;
  border-radius: 7px;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s all ease;
}

.navbar .navbarRight .navLink.hasDropdown .dropdownMenu ul {
  list-style-type: none;
}

.navbar .navbarRight .navLink.hasDropdown .dropdownMenu ul li {
  padding: 10px;
}

.navbar .navbarRight .navLink.hasDropdown .dropdownMenu ul li a {
  color: #000;
  font-size: 0.85rem;
}

.navbar .navbarRight .navLink:hover.hasDropdown .dropdownMenu {
  opacity: 1;
  pointer-events: all;
}

.navbar .navbarRight .navLink:not(:first-child) {
  margin-left: 30px;
}

.navbar .menuHamburger {
  display: none;
}

@media only screen and (max-width: 992px) {
  .navbar {
    padding: 10px 20px;
  }
  .navbar .navbarLeft h3 {
    display: none;
  }
  .navbar .navbarRight {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: -100vw;
    background-color: #000;
    padding: 30vh 50px;
    transition: 0.5s all ease;
  }
  .navbar .navbarRight .navLink:not(:first-child) {
    margin-left: 0;
  }
  .navbar .navbarRight.true {
    right: 0;
  }
  .navbar .menuHamburger {
    display: block;
    z-index: 6;
  }
  .navbar .menuHamburger i {
    color: #fff !important;
    font-size: 1.5rem;
  }
}
/*# sourceMappingURL=Navbar.css.map */
.whatsappCta {
  position: fixed;
  bottom: 2em;
  right: 2em;
  z-index: 4;
}

.whatsappCta img {
  height: 50px;
}

.footer {
  padding: 50px 0 0;
  background-color: #000;
  color: #fff;
}

.footer .footerTop {
  display: grid;
  grid-template-columns: 2.5fr 2fr 1fr;
  grid-gap: 1em;
  padding: 0 10vw;
}

.footer .footerTop section .sectionHeading {
  font-weight: 700;
  font-size: 1.25rem;
}

.footer .footerTop .section1 p {
  padding-top: 1rem;
  width: 65%;
  color: #c6c6c6;
  text-align: justify;
  font-size: 0.8rem;
}

.footer .footerTop .section1 a {
  color: #c6c6c6;
  text-decoration: none;
}

.footer .footerTop .section1 a:hover {
  color: var(--theme-clr);
  text-decoration: underline;
}

.footer .footerTop .section2 p {
  width: 75%;
  /* text-align: justify; */
}

.footer .footerTop section .sectionSocial {
  display: flex;
  margin-top: 1.3em;
}

.footer .footerTop section .sectionSocial a {
  color: var(--theme-clr);
  font-size: 1.2rem;
}

.footer .footerTop section .sectionSocial a:not(:first-child) {
  margin-left: 1.3em;
}

.footer .footerTop section .sectionLinks {
  list-style-type: none;
  font-size: 0.85rem;
}

.footer .footerTop section .sectionLinks li {
  position: relative;
  margin-top: 1.3em;
  padding-left: 1.3em;
}

.footer .footerTop section .sectionLinks li a {
  text-decoration: none;
  color: #fff;
  transition: 0.3s all ease;
}

.footer .footerTop section .sectionLinks li a:hover {
  color: var(--theme-clr);
}

.footer .footerTop section .sectionLinks li::before {
  position: absolute;
  content: "\f105";
  font-family: "FontAwesome";
  color: #fff;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.footer .footerTop section.section1 .sectionHeading {
  letter-spacing: 2px;
}

.footer .footerBottom {
  margin-top: 2rem;
  border-top: 1px solid #2f2f2f;
  padding: 15px 5vw 25px;
  text-align: center;
  font-size: 0.75rem;
}

.footer .footerBottom a {
  color: #fff;
  text-decoration: none;
}

.footer .footerBottom a:hover {
  color: var(--theme-clr);
  text-decoration: underline;
}

@media only screen and (max-width: 992px) {
  .footer {
    padding: 75px 0 15px;
  }
  .footer .footerTop {
    grid-template-columns: repeat(1, 1fr);
  }
  .footer .footerTop section .sectionHeading {
    font-size: 1.25rem;
  }
  .footer .footerTop section .sectionLinks {
    font-size: 0.95rem;
  }
  .footer .footerBottom {
    font-size: 2rem;
  }
}
/*# sourceMappingURL=Footer.css.map */
.homeCont .section {
  overflow: hidden;
}

.homeCont .section.section1 {
  height: 100vh;
  position: relative;
  background-image: url(/static/media/cover.2b003dc1.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 0 15vw;
}

.homeCont .section.section1::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
}

.homeCont .section.section1 .left {
  z-index: 4;
  text-align: center;
}

.homeCont .section.section1 .left h1 {
  font-size: 2.5rem;
}

.homeCont .section.section1 .left p {
  margin-top: 2em;
}

.homeCont .section.whatWeDo {
  padding: 70px 15vw;
}

.homeCont .section.whatWeDo .title {
  font-size: 2.5rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1em;
}

.homeCont .section.whatWeDo .singleDo {
  border-radius: 10px;
  border-bottom: 5px solid #000;
  box-shadow: 0 0.4rem 1rem -0.01rem rgba(0, 0, 0, 0.1);
  padding: 20px 0;
}

.homeCont .section.whatWeDo .singleDo .image {
  padding: 0 15px;
}

.homeCont .section.whatWeDo .singleDo .image img {
  width: 100%;
}

.homeCont .section.whatWeDo .singleDo .doTitle {
  text-align: center;
  margin-top: 2em;
  font-weight: 500;
}

.homeCont .section.whatWeDo .singleDo.pink {
  border-bottom-color: #e5276a;
}

.homeCont .section.whatWeDo .singleDo.blue {
  border-block-color: #21b7b9;
}

.homeCont .section.whatWeDo .singleDo.purple {
  border-block-color: #a944a5;
}

.homeCont .section.whatWeDo .singleDo.orange {
  border-block-color: #f6910f;
}

.homeCont .section.section5 {
  background-color: #f7f7f7;
  margin: 2em 0;
  padding: 70px 10vw;
}

.homeCont .section.section5 .sectionTitle {
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1em;
}

.homeCont .section.section5 .impactsList {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1em 3em;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.homeCont .section.section5 .impactsList .singleImpact {
  text-align: center;
}

.homeCont .section.section5 .impactsList .singleImpact .impactTitle {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
}

.homeCont .section.section5 .impactsList .singleImpact .impactCircle {
  position: relative;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 17px 17px 34px #d9d9d9, -17px -17px 34px #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1rem;
  margin-top: 3em;
  padding: 10px;
}

.homeCont .section.section5 .impactsList .singleImpact .impactCircle .impactIcon {
  position: absolute;
  background-color: var(--theme-clr);
  color: #fff;
  padding: 20px;
  font-size: 1.5rem;
  top: 0;
  transform: translateY(-50%);
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeCont .section.section5 .impactsList .singleImpact .impactCircle .impactNumber {
  font-weight: 600;
  font-size: 1rem;
}

.homeCont .section.section5 .impactsList .singleImpact .impactCircle .impactText {
  font-size: 0.8rem;
  margin-top: 0.5em;
  color: #616161;
}

.homeCont .section.section6 {
  padding: 70px 15vw;
}

.homeCont .section.section6 .sectionTitle {
  font-size: 2.5rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1em;
}

.homeCont .section.section6 .owl-theme .logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeCont .section.section7 {
  padding: 70px 15vw;
}

.homeCont .section.section7 .sectionTitle {
  font-size: 2.5rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1em;
}

.homeCont .section.section7 .faq_part {
  margin-bottom: 3em;
  width: 100%;
  color: #000;
}

.homeCont .section.section7 .faq_part .faqs {
  width: 100%;
}

.homeCont .section.section7 .faq_part .faqs .faq {
  margin: 15px 5px;
  padding: 15px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.homeCont .section.section7 .faq_part .faqs .faq .faq-question {
  position: relative;
  font-size: 1.3rem;
  padding-right: 80px;
  font-weight: 500;
  transition: all 0.4s ease;
}

.homeCont .section.section7 .faq_part .faqs .faq .faq-question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-image: url(/static/media/arrow-down-mint.2999d905.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.4s ease-out;
}

.homeCont .section.section7 .faq_part .faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
}

.homeCont .section.section7 .faq_part .faqs .faq .faq-answer a {
  color: var(--theme-clr);
}

.homeCont .section.section7 .faq_part .faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.homeCont .section.section7 .faq_part .faqs .faq.open .faq-question::after {
  transform: translateY(-50%) rotate(180deg);
}

.homeCont .section.section7 .faq_part .faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .homeCont .section {
    min-height: 100vh;
  }
  .homeCont .section.section1 {
    padding: 0 15px;
  }
  .homeCont .section.section1 .left h1 {
    font-size: 2rem;
  }
  .homeCont .section.section1 .left p {
    font-size: 0.9rem;
  }
  .homeCont .section.section2 .slide, .homeCont .section.section3 .slide, .homeCont .section.section4 .slide {
    flex-direction: column;
  }
  .homeCont .section.section2 .slide .slideContent, .homeCont .section.section3 .slide .slideContent, .homeCont .section.section4 .slide .slideContent {
    margin: 2.5em 15px 0;
  }
  .homeCont .section.section2 .slide .slideContent .slideText, .homeCont .section.section3 .slide .slideContent .slideText, .homeCont .section.section4 .slide .slideContent .slideText {
    margin-top: 0.5em;
  }
  .homeCont .section.section2 .slide .slideImage, .homeCont .section.section3 .slide .slideImage, .homeCont .section.section4 .slide .slideImage {
    border: none;
    height: 50vh;
    width: calc(0.75 * 50vh);
    border-radius: 10px;
    margin-top: 3em;
  }
  .homeCont .section.section2:nth-child(odd) .slide, .homeCont .section.section3:nth-child(odd) .slide, .homeCont .section.section4:nth-child(odd) .slide {
    flex-direction: column;
  }
  .homeCont .section.section2:nth-child(odd) .slide .slideImage, .homeCont .section.section3:nth-child(odd) .slide .slideImage, .homeCont .section.section4:nth-child(odd) .slide .slideImage {
    border-radius: 10px;
    border: none;
  }
  .homeCont .section.section5 {
    padding: 70px 20px;
  }
  .homeCont .section.section6 {
    padding: 50px 20px;
    min-height: 0;
  }
  .homeCont .section.section7 {
    padding: 50px 20px;
  }
}

@media only screen and (min-width: 992px) {
  .homeCont .section.section5 .impactsList {
    grid-template-columns: repeat(6, 1fr);
  }
  .homeCont .section.section5 .impactsList .singleImpact {
    grid-column: span 2;
  }
  .homeCont .section.section5 .impactsList .singleImpact:last-child:nth-child(3n - 1) {
    grid-column-end: -2;
  }
  .homeCont .section.section5 .impactsList .singleImpact:nth-last-child(2):nth-child(3n + 1) {
    grid-column-end: 4;
  }
  .homeCont .section.section5 .impactsList .singleImpact:last-child:nth-child(3n - 2) {
    grid-column-end: 5;
  }
}
/*# sourceMappingURL=Home.css.map */
/* No CSS *//*# sourceMappingURL=AboutUs.css.map */
.contactUsCont .section {
  min-height: 100vh;
}

.contactUsCont .section.section1 {
  position: relative;
  background-image: url(/static/media/cover.80be58ac.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3em;
  justify-items: center;
  align-items: center;
  color: #fff;
  padding: 15vh 8vw 5vh;
}

.contactUsCont .section.section1::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
}

.contactUsCont .section.section1 .left {
  z-index: 4;
  text-align: center;
}

.contactUsCont .section.section1 .left h1 {
  font-size: 3.5rem;
}

.contactUsCont .section.section1 .left p {
  margin-top: 2em;
}

.contactUsCont .section.section1 .right {
  z-index: 4;
  justify-self: stretch;
}

.contactUsCont .section.section1 .right form {
  padding: 50px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  text-align: center;
}

.contactUsCont .section.section1 .right form .formHeading {
  text-align: left;
  font-size: 2em;
  font-weight: 600;
}

.contactUsCont .section.section1 .right form .inputGroup input,
.contactUsCont .section.section1 .right form .inputGroup textarea {
  width: 100%;
  border: none;
  background: transparent;
  color: #fff;
  border-bottom: 1px solid #fff;
  padding: 15px 25px;
  outline: none;
}

.contactUsCont .section.section1 .right form .inputGroup input::placeholder,
.contactUsCont .section.section1 .right form .inputGroup textarea::placeholder {
  color: #fff;
}

.contactUsCont .section.section1 .right form .inputGroup textarea {
  border: 1px solid #fff;
  border-radius: 7px;
  margin-top: 1em;
}

.contactUsCont .section.section1 .right form .inputGroup button {
  width: 100%;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  padding: 15px 45px;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.contactUsCont .section.section1 .right form .inputGroup button:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid #fff;
}

.contactUsCont .section.section1 .right form .inputGroup.items2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1em;
}

.contactUsCont .section.section1 .right form .inputGroup:not(:first-child) {
  margin-top: 1.5em;
}

.contactUsCont .section.section2 {
  padding: 70px 15vw 0;
}

.contactUsCont .section.section2 .sectionTitle {
  font-size: 2.5rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1em;
}

.contactUsCont .section.section2 .faq_part {
  margin-bottom: 0;
  width: 100%;
  color: #000;
}

.contactUsCont .section.section2 .faq_part .faqs {
  width: 100%;
}

.contactUsCont .section.section2 .faq_part .faqs .faq {
  margin: 15px 5px;
  padding: 15px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.contactUsCont .section.section2 .faq_part .faqs .faq .faq-question {
  position: relative;
  font-size: 1.3rem;
  padding-right: 80px;
  font-weight: 500;
  transition: all 0.4s ease;
}

.contactUsCont .section.section2 .faq_part .faqs .faq .faq-question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-image: url(/static/media/arrow-down-mint.2999d905.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.4s ease-out;
}

.contactUsCont .section.section2 .faq_part .faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
}

.contactUsCont .section.section2 .faq_part .faqs .faq .faq-answer a {
  color: var(--theme-clr);
}

.contactUsCont .section.section2 .faq_part .faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.contactUsCont .section.section2 .faq_part .faqs .faq.open .faq-question::after {
  transform: translateY(-50%) rotate(180deg);
}

.contactUsCont .section.section2 .faq_part .faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .contactUsCont .section.section1 {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3em;
    padding: 15vh 20px 5vh;
  }
  .contactUsCont .section.section1 .left h1 {
    font-size: 2rem;
  }
  .contactUsCont .section.section1 .right form {
    padding: 25px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    text-align: center;
  }
  .contactUsCont .section.section1 .right form .formHeading {
    text-align: left;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .contactUsCont .section.section1 .right form .inputGroup input,
  .contactUsCont .section.section1 .right form .inputGroup textarea {
    padding: 15px;
  }
  .contactUsCont .section.section1 .right form .inputGroup.items2 {
    grid-template-columns: repeat(1, 1fr);
  }
  .contactUsCont .section.section1 .right form .inputGroup:not(:first-child) {
    margin-top: 1.2em;
  }
  .contactUsCont .section.section2 {
    padding: 70px 20px;
  }
  .contactUsCont .section.section2 .sectionTitle {
    font-size: 2rem;
  }
}
/*# sourceMappingURL=ContactUs.css.map */
.volunteerCont .section {
  min-height: 100vh;
}

.volunteerCont .section.section1 {
  position: relative;
  background-image: url(/static/media/cover.a7e0f355.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 0 15vw;
}

.volunteerCont .section.section1::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
}

.volunteerCont .section.section1 .left {
  z-index: 4;
  text-align: center;
}

.volunteerCont .section.section1 .left h1 {
  font-size: 2.5rem;
}

.volunteerCont .section.section1 .left p {
  margin-top: 2em;
}

.volunteerCont .section.section2 {
  position: relative;
  background-image: url(/static/media/our-core-values.f2480baf.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 0 15vw;
}

.volunteerCont .section.section2::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
}

.volunteerCont .section.section2 .left {
  z-index: 4;
  text-align: center;
}

.volunteerCont .section.section2 .left .text .sectionHeading {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 2em;
}

.volunteerCont .section.section2 .left .text .values {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2em;
  justify-content: center;
}

.volunteerCont .section.section2 .left .text .values .singleValue i {
  font-size: 2.5em;
  color: var(--theme-clr);
}

.volunteerCont .section.section2 .left .text .values .singleValue p {
  margin-top: 2em;
}

.volunteerCont .section.section3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
  padding: 10vh 0;
}

.volunteerCont .section.section3 .left img {
  height: 70vh;
  border-radius: 20px;
}

.volunteerCont .section.section3 .right {
  text-align: center;
  justify-self: stretch;
  margin: 0 10%;
}

.volunteerCont .section.section3 .right form {
  padding: 50px;
  box-shadow: 30px 30px 60px #d9d9d9, -30px -30px 60px #d9d9d9;
  border-radius: 20px;
  text-align: center;
}

.volunteerCont .section.section3 .right form .formHeading {
  text-align: left;
  font-size: 2em;
  font-weight: 600;
}

.volunteerCont .section.section3 .right form .formSubheading {
  text-align: left;
  font-size: 1rem;
  font-weight: 500;
  color: var(--theme-clr);
}

.volunteerCont .section.section3 .right form .inputGroup input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #000;
  padding: 15px 25px;
  outline: none;
}

.volunteerCont .section.section3 .right form .inputGroup button {
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
  padding: 15px 45px;
  outline: none;
  cursor: pointer;
}

.volunteerCont .section.section3 .right form .inputGroup:not(:first-child) {
  margin-top: 2em;
}

.volunteerCont .heading {
  font-size: 2.5em;
  font-weight: 600;
  text-align: center;
  margin: 2em 0;
}

@media only screen and (max-width: 992px) {
  .volunteerCont .section.section1 {
    padding: 0 20px;
  }
  .volunteerCont .section.section1 .left h1 {
    font-size: 2rem;
  }
  .volunteerCont .section.section1 .left p {
    font-size: 0.9rem;
    margin-top: 2em;
  }
  .volunteerCont .section.section2 {
    padding: 0 20px;
  }
  .volunteerCont .section.section2 .left .text .sectionHeading {
    font-size: 2rem;
    margin-bottom: 2em;
  }
  .volunteerCont .section.section2 .left .text .values {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5em;
  }
  .volunteerCont .section.section2 .left .text .values .singleValue i {
    font-size: 2rem;
  }
  .volunteerCont .section.section2 .left .text .values .singleValue p {
    margin-top: 1.5em;
  }
  .volunteerCont .section.section3 {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 4em;
    padding: 5vh 20px;
  }
  .volunteerCont .section.section3 .left img {
    width: 60vw;
    height: auto;
  }
  .volunteerCont .section.section3 .right {
    margin: 0;
  }
  .volunteerCont .section.section3 .right form {
    padding: 25px;
    box-shadow: 15px 15px 30px #d9d9d9, -15px -15px 30px #d9d9d9;
    border-radius: 20px;
    text-align: center;
  }
  .volunteerCont .section.section3 .right form .formHeading {
    font-size: 1.5rem;
  }
  .volunteerCont .section.section3 .right form .formSubheading {
    font-size: 0.75rem;
  }
  .volunteerCont .section.section3 .right form .inputGroup input {
    padding: 15px;
  }
  .volunteerCont .section.section3 .right form .inputGroup:not(:first-child) {
    margin-top: 1.2em;
  }
}
/*# sourceMappingURL=Volunteer.css.map */
.donateCont .heading {
  text-align: center;
  font-weight: 600;
  font-size: 2.5rem;
  margin-top: 70px;
}

.donateCont .section.section1 {
  position: relative;
  height: 100vh;
  background-image: url(/static/media/cover.667d0a40.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: flex-end;
  color: #fff;
  padding: 0 10vw 25vh;
}

.donateCont .section.section1::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
}

.donateCont .section.section1 .left {
  z-index: 4;
}

.donateCont .section.section1 .left .text h1 {
  font-size: 3rem;
}

.donateCont .section.section1 .left .text p {
  margin-top: 0.5em;
  font-size: 1.1rem;
}

.donateCont .section.section1 .left .text button {
  background-color: var(--theme-clr);
  color: #fff;
  border: 1px solid var(--theme-clr);
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 7px 30px;
  margin-top: 1em;
  outline: none;
  cursor: pointer;
}

.donateCont .section.section2 {
  margin: 70px 15vw;
  padding: 50px;
  background-color: #f8f8f8;
}

.donateCont .section.section2 .sectionHeading {
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
}

.donateCont .section.section2 h4 {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
}

.donateCont .section.section2 .amountsList {
  display: flex;
  justify-content: center;
  margin-top: 2em;
}

.donateCont .section.section2 .amountsList .singleAmount {
  background-color: var(--theme-soft-clr);
  color: var(--theme-clr);
  border: 2px solid var(--theme-clr);
  text-align: center;
  padding: 10px 35px;
  width: -webkit-min-content;
  width: min-content;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.1rem;
  transition: 0.3s all ease;
}

.donateCont .section.section2 .amountsList .singleAmount:hover {
  background-color: var(--theme-clr);
  color: #fff;
}

.donateCont .section.section2 .amountsList .singleAmount:not(:first-child) {
  margin-left: 2em;
}

.donateCont .section.section3, .donateCont .section.section4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 70px 10vw;
}

.donateCont .section.section3 .left img, .donateCont .section.section4 .left img {
  width: 100%;
  border-radius: 20px;
}

.donateCont .section.section3 .left img.smallImage, .donateCont .section.section4 .left img.smallImage {
  width: 50%;
}

.donateCont .section.section3 .right, .donateCont .section.section4 .right {
  margin-top: 3em;
}

.donateCont .section.section3 .right .sectionHeading, .donateCont .section.section4 .right .sectionHeading {
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
}

.donateCont .section.section3 .right p, .donateCont .section.section4 .right p {
  text-align: left;
  margin-top: 1em;
}

.donateCont .section.section5 {
  display: flex;
  flex-direction: column;
  margin: 70px 10vw;
}

.donateCont .bottomDonate {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  min-height: 10vh;
  padding: 20px 25vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f8f8f8;
  opacity: 0;
  pointer-events: none;
  z-index: 999;
  transition: 0.3s all ease;
}

.donateCont .bottomDonate h4 {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1em;
}

.donateCont .bottomDonate .amountsList {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.donateCont .bottomDonate .amountsList .singleAmount {
  background-color: var(--theme-soft-clr);
  color: var(--theme-clr);
  border: 2px solid var(--theme-clr);
  text-align: center;
  padding: 5px 35px;
  width: -webkit-min-content;
  width: min-content;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.1rem;
  transition: 0.3s all ease;
}

.donateCont .bottomDonate .amountsList .singleAmount:hover {
  background-color: var(--theme-clr);
  color: #fff;
}

.donateCont .bottomDonate.show {
  opacity: 1;
  pointer-events: all;
}

.donateCont .upiFloat {
  position: fixed;
  bottom: 2em;
  left: 2em;
  z-index: 4;
  background-color: var(--theme-clr);
  padding: 10px 30px;
  font-size: 0.9rem;
  border-radius: 30px;
  color: #fff;
  border: 2px solid var(--theme-clr);
  transition: 0.3s all ease;
}

.donateCont .upiFloat:hover {
  background: none;
  color: var(--theme-clr);
}

@media only screen and (max-width: 992px) {
  .donateCont .heading {
    font-size: 2rem;
  }
  .donateCont .section.section1 {
    padding: 0 20px 25vh;
  }
  .donateCont .section.section1 .left .text h1 {
    font-size: 2rem;
  }
  .donateCont .section.section2 {
    padding: 70px 20px;
  }
  .donateCont .section.section2 .sectionHeading {
    font-size: 1.8rem;
  }
  .donateCont .section.section2 .amountsList {
    flex-direction: column;
  }
  .donateCont .section.section2 .amountsList .singleAmount {
    width: auto;
  }
  .donateCont .section.section2 .amountsList .singleAmount:not(:first-child) {
    margin: 1.5em 0 0;
  }
  .donateCont .section.section3, .donateCont .section.section4 {
    margin: 70px 20px;
  }
  .donateCont .section.section3 .left img.smallImage, .donateCont .section.section4 .left img.smallImage {
    width: 100%;
  }
  .donateCont .section.section3 .right, .donateCont .section.section4 .right {
    margin-top: 3em;
  }
  .donateCont .section.section3 .right .sectionHeading, .donateCont .section.section4 .right .sectionHeading {
    font-size: 2.2rem;
  }
  .donateCont .section.section3 .right p, .donateCont .section.section4 .right p {
    font-size: 0.9rem;
  }
  .donateCont .section.section5 {
    margin: 70px 20px;
  }
  .donateCont .bottomDonate {
    padding: 15px 10vw;
  }
  .donateCont .bottomDonate a:first-child {
    display: none;
  }
  .donateCont .upiFloat {
    position: static;
    margin: 0 auto 50px;
    text-align: center;
    width: -webkit-min-content;
    width: min-content;
    white-space: nowrap;
  }
}
/*# sourceMappingURL=Donate.css.map */
.ppcontent{
    /* border: 2px solid red; */
    padding: 120px 10vw;
}
.ppcontent h2{
    /* border: 2px solid beige; */
    font-size: 1.5rem;
}
.ppcontent p.updatedTime{
    /* border: 2px solid beige; */
    color: #616161;
    padding-top: 1rem;    
    font-size: 0.8rem;
    font-weight: bold;
}

.ppcontent .pp-intro p{
    padding-top: 1rem;
    font-size: 0.85rem;
}

.ppcontent .pp-middle-content .m1 h4{
    padding-top: 1.8rem;
    font-size: 1rem;
}
.ppcontent .pp-middle-content .m1 p{
    padding-top: 1rem;
    font-size: 0.85rem;
}
.ppcontent .pp-middle-content .m1 ul li{
    padding-top: 1rem;
    font-size: 0.85rem;
}
.tccontent{
    /* border: 2px solid blueviolet; */
    padding: 120px 10vw;
}
.tccontent h2{
    font-size: 1.5rem;
}

.tccontent p.updatedTime{
    color: #616161;
    padding-top: 1rem;    
    font-size: 0.8rem;
    font-weight: bold;
}

.tccontent .tc-intro p{
    padding-top: 1rem;
    font-size: 0.85rem;
}
.tccontent .tc-intro p:last-child{
    padding-top: 1rem;
    font-size: 0.85rem;
    font-weight: 600;
}

.tccontent .tc-lists ul li{
    padding-top: 1rem;
    font-size: 0.85rem;
}
.ourTeamCont {
  margin: calc(10vh + 70px) 20px;
}

.ourTeamCont .lineTitle {
  position: relative;
  font-family: "Montserrat", sans-serif;
  border-left: 4px solid #ff9800;
  margin-left: 20px;
  font-size: 1.7em;
}

.ourTeamCont .lineTitle span {
  display: inline-block;
  background-color: #fff;
  padding: 0 10px 0 20px;
  font-weight: normal;
  cursor: pointer;
}

.ourTeamCont .lineTitle span strong {
  font-weight: 600;
}

.ourTeamCont .lineTitle span.opened::after {
  content: "\f106";
  font-family: "FontAwesome";
  margin-left: 0.5em;
}

.ourTeamCont .lineTitle span.closed::after {
  content: "\f107";
  font-family: "FontAwesome";
  margin-left: 0.5em;
}

.ourTeamCont .lineTitle::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.5em;
  width: 95%;
  border-top: 1px solid #c5c5c5;
  z-index: -1;
}

.ourTeamCont .teamList {
  margin-top: 3em;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  grid-gap: 1.5em;
}

@media only screen and (min-width: 768px) {
  .ourTeamCont .teamList {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 992px) {
  .ourTeamCont .lineTitle {
    margin-left: 50px;
  }
}

@media only screen and (min-width: 1200px) {
  .ourTeamCont .teamList {
    grid-template-columns: repeat(3, 1fr);
  }
}
/*# sourceMappingURL=OurTeam.css.map */
.personCard {
  position: relative;
  width: 300px;
  box-shadow: 12px 12px 29px #d6d6d6, -12px -12px 29px #ffffff;
  border-radius: 10px;
  margin: 0 auto 1.5em;
  overflow: hidden;
}

.personCard .personImage {
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.personCard .personDetails {
  color: #000;
  text-decoration: none;
  text-align: center;
  padding: 40px 20px;
  min-height: 170px;
}

.personCard .personDetails h4 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  font-weight: 600;
}

.personCard .personDetails .personPost {
  font-weight: 500;
}

.personCard .personHover {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 153, 0, 0.7);
  border-radius: 10px;
  color: #fff;
  top: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
  transition: 0.3s all ease;
}

.personCard .personHover .cta {
  display: block;
  color: #fff;
  text-decoration: none;
  border: 2px solid #fff;
  border-radius: 5px;
  padding: 10px 0;
  width: 170px;
  margin: 1em auto 0;
  font-size: 0.8em;
  font-weight: bold;
  transition: 0.3s all ease;
}

.personCard .personHover .cta:hover {
  background-color: #fff;
  color: #ff9800;
  text-decoration: none;
}

.personCard .personHover .social {
  margin-top: 1.5em;
}

.personCard .personHover .social a {
  color: #fff;
}

.personCard .personHover .social a i {
  font-size: 1.3em;
}

.personCard .personHover .social a:not(:last-child) {
  margin-right: 1em;
}

.personCard:hover .personHover {
  top: 0;
  opacity: 1;
  pointer-events: all;
}
/*# sourceMappingURL=SingleMember.css.map */
.partnerCont .section.section1 {
  position: relative;
  height: 100vh;
  background-image: url(/static/media/cover.193cd87d.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: flex-end;
  color: #fff;
  padding: 0 10vw 25vh;
}

.partnerCont .section.section1::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
}

.partnerCont .section.section1 .left {
  z-index: 4;
}

.partnerCont .section.section1 .left .text h1 {
  font-size: 3rem;
}

.partnerCont .section.section1 .left .text p {
  margin-top: 0.5em;
  font-size: 1.1rem;
}

.partnerCont .section.section1 .left .text button {
  background-color: var(--theme-clr);
  color: #fff;
  border: 1px solid var(--theme-clr);
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 7px 30px;
  margin-top: 1em;
  outline: none;
  cursor: pointer;
}

.partnerCont .section.section2, .partnerCont .section.section3 {
  padding: 70px 10vw;
}

.partnerCont .section.section2 .sectionHeading, .partnerCont .section.section3 .sectionHeading {
  font-size: 2.3rem;
  font-weight: 600;
  text-align: center;
}

.partnerCont .section.section2 .sectionSubheading, .partnerCont .section.section3 .sectionSubheading {
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
}

.partnerCont .section.section2 form, .partnerCont .section.section3 form {
  width: 80%;
  margin: 3em auto 0;
  text-align: center;
}

.partnerCont .section.section2 form .formHeading, .partnerCont .section.section3 form .formHeading {
  text-align: left;
  font-weight: 500;
  font-size: 1.1rem;
  margin-top: 3.5em;
}

.partnerCont .section.section2 form .inputGroup, .partnerCont .section.section3 form .inputGroup {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 4em 3em;
  margin-top: 1.2em;
}

.partnerCont .section.section2 form .inputGroup input,
.partnerCont .section.section2 form .inputGroup textarea, .partnerCont .section.section3 form .inputGroup input,
.partnerCont .section.section3 form .inputGroup textarea {
  border: none;
  border-bottom: 2px solid #000;
  padding: 15px;
  outline: none;
}

.partnerCont .section.section2 form .inputGroup textarea, .partnerCont .section.section3 form .inputGroup textarea {
  border: 1px solid #000;
  border-radius: 5px;
}

.partnerCont .section.section2 form .inputGroup.twoInputs, .partnerCont .section.section3 form .inputGroup.twoInputs {
  grid-template-columns: repeat(2, 1fr);
}

.partnerCont .section.section2 form button, .partnerCont .section.section3 form button {
  padding: 15px 50px;
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
  margin-top: 2em;
  outline: none;
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .partnerCont .section.section1 {
    padding: 0 20px 25vh;
  }
  .partnerCont .section.section1 .left .text h1 {
    font-size: 2rem;
  }
  .partnerCont .section.section2, .partnerCont .section.section3 {
    padding: 50px 20px;
  }
  .partnerCont .section.section2 .sectionHeading, .partnerCont .section.section3 .sectionHeading {
    font-size: 2rem;
  }
  .partnerCont .section.section2 .sectionSubheading, .partnerCont .section.section3 .sectionSubheading {
    font-size: 1rem;
  }
  .partnerCont .section.section2 form, .partnerCont .section.section3 form {
    width: 100%;
  }
  .partnerCont .section.section2 form .inputGroup, .partnerCont .section.section3 form .inputGroup {
    grid-row-gap: 1em;
    margin-top: 1.2em;
  }
  .partnerCont .section.section2 form .inputGroup.twoInputs, .partnerCont .section.section3 form .inputGroup.twoInputs {
    grid-template-columns: repeat(1, 1fr);
  }
}
/*# sourceMappingURL=Partner.css.map */
